import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styled, { css } from 'styled-components/macro';

import BackgroundImage from 'gatsby-background-image';
import Layout from 'src/components/Layout';
import SEO from 'src/components/Seo';
import {
  FacebookLogoIcon,
  InstagramLogoIcon,
  LinkedinLogoIcon,
  YelpLogoIcon,
} from 'src/components/icons';
import StyledContentWrapper from 'src/components/StyledContentWrapper';
import { H1, basePStyles } from 'src/components/Styled';
import { encodeUrlQueryParams } from 'src/utils/encodeUrlQueryParams';

import * as colors from 'src/constants/colors';
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  YELP_URL,
} from 'src/constants/urls';
import {
  DESKTOP_SCREEN_WIDTH_PX,
  TABLET_SCREEN_WIDTH_PX,
  TABLET_CONTENT_HZ_PADDING_PX,
  MOBILE_CONTENT_HZ_PADDING_PX,
  LARGE_MOBILE_SCREEN_WIDTH_PX,
  HERO_HEIGHTS_BY_PAGE,
} from 'src/constants/layout';

const MOBILE_LABEL_WIDTH_PX = 92;
const TABLET_LABEL_WIDTH_PX = 130;

const baseInputStyles = css`
  width: 100%;
  display: block;
  padding: 0.5rem;
  border: 1px solid ${colors.inputBorderGray};
  border-radius: 4px;
  margin-top: 0.25rem;
  box-sizing: border-box;
`;
const Styled = {
  HeroSection: styled.section`
    height: ${HERO_HEIGHTS_BY_PAGE.contact.height};
    min-height: ${HERO_HEIGHTS_BY_PAGE.contact['min-height']};
    max-height: ${HERO_HEIGHTS_BY_PAGE.contact['max-height']};
    color: ${colors.black};
    position: relative;
  `,
  HeroContentWrapper: styled(StyledContentWrapper)``,
  LogoImage: styled.img`
    margin-bottom: 2rem;
  `,
  BackgroundImage: styled(BackgroundImage)`
    min-height: 100%;
    background-position: bottom center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  ContentWrapper: styled(StyledContentWrapper)`
    position: relative;
    padding: 0;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      padding: 0 15%;
    }
  `,
  ContactInfoSection: styled.section`
    display: flex;
    flex-direction: column;
    position: relative;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      flex-direction: row;
      align-items: stretch;
    }
  `,
  MainContactInfo: styled.div`
    flex: 1;
    background: ${colors.darkBlue};
    color: ${colors.white};
    padding: ${2 * MOBILE_CONTENT_HZ_PADDING_PX}px ${MOBILE_CONTENT_HZ_PADDING_PX}px;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      padding: ${TABLET_CONTENT_HZ_PADDING_PX}px;
      margin-top: -12px;
      margin-bottom: -12px;
    }
    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      padding: 6rem;
    }
  `,
  SocialMediaContactInfo: styled.div`
    flex: 1;
    background: ${colors.grayBg1};
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.002em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${1.5 * MOBILE_CONTENT_HZ_PADDING_PX}px
      ${MOBILE_CONTENT_HZ_PADDING_PX}px;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      padding: ${TABLET_CONTENT_HZ_PADDING_PX}px;
      align-items: flex-start;
    }
    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      padding: 2rem 5rem;
    }
  `,
  SocialMediaLinks: styled.div`
    display: flex;
    margin-top: 1rem;
  `,
  SocialMediaLink: styled.a`
    &:not(:last-child) {
      margin-right: 1.25rem;
    }
  `,
  ContactInfoRow: styled.div`
    ${basePStyles}
    line-height: 122%;
    display: flex;
    &:not(:first-child) {
      margin-top: 1.5rem;
    }

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      font-size: 18px;
    }
  `,
  Label: styled.div`
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.002em;
    width: ${MOBILE_LABEL_WIDTH_PX}px;
    min-width: ${MOBILE_LABEL_WIDTH_PX}px;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      font-size: 20px;
      width: ${TABLET_LABEL_WIDTH_PX}px;
      min-width: ${TABLET_LABEL_WIDTH_PX}px;
    }
  `,
  ContactValue: styled.span`
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: calc(100% - ${MOBILE_LABEL_WIDTH_PX}px);
    flex: 1;
    text-align: right;
    font-weight: 300;
    > a {
      text-decoration: none;
      color: inherit;
    }

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      max-width: calc(100% - ${TABLET_LABEL_WIDTH_PX}px);
      text-align: left;
    }
  `,
  FormSection: styled.section`
    background: ${colors.white};
    padding: 3rem ${MOBILE_CONTENT_HZ_PADDING_PX}px;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      padding: 8rem 2rem;
    }
  `,
  ContactFormWrapper: styled.div``,
  ContactFormTitle: styled.h2`
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
  `,
  ContactForm: styled.form``,
  FormLabel: styled.label`
    width: 100%;
    display: block;
    margin-top: 1rem;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.001em;
    color: ${colors.darkGray};
  `,
  NameInputs: styled.div`
    @media only screen and (min-width: ${LARGE_MOBILE_SCREEN_WIDTH_PX}px) {
      display: flex;
      > :first-child {
        margin-right: 0.5rem;
      }
      > :last-child {
        margin-left: 0.5rem;
      }
    }
  `,
  Input: styled.input`
    ${baseInputStyles}
    flex: 1;
  `,
  TextArea: styled.textarea`
    ${baseInputStyles}
  `,
  SubmitButton: styled.button`
    width: 100%;
    box-sizing: border-box;
    background: ${colors.darkBlue};
    color: ${colors.white};
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
    letter-spacing: 0.001em;
    padding: 0.75rem 2rem;
    margin-top: 1.25rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      background: ${colors.grayBg1};
      cursor: default;
    }
  `,
  SuccessMessage: styled.div`
    margin-top: 1.25rem;
    font-family: Rubik;
    font-size: 14px;
  `,
};

interface Props {
  title?: string;
}
const ContactForm: React.FC<Props> = ({ title }) => {
  const [formState, setFormState] = useState<Record<string, string>>({});
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState('');

  const handleChange = (e: React.MouseEvent<HTMLInputElement>) => {
    setSubmitSuccessMsg('');
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeUrlQueryParams({
        'form-name': form.getAttribute('name'),
        ...formState,
      }),
    })
      .then((res: any) => {
        if (res.status < 200 || res.status > 299) {
          throw new Error(res);
        }
        setSubmitSuccessMsg(
          `Thanks for reaching out ${formState.firstName}! We've received your message and will get back to you soon.`,
        );
      })
      .catch(error => {
        // TODO-PL: Report errors to a reliable error tracking service, eg. Sentry (https://sentry.io)
        console.error(error);
        alert(
          'Sorry! Something went wrong. Please try again later or give us a call at (323) 726-9424 if the issue persists!',
        );
      });
  };

  return (
    <Styled.ContactFormWrapper>
      <Styled.ContentWrapper>
        {title && <Styled.ContactFormTitle>{title}</Styled.ContactFormTitle>}
        <Styled.ContactForm
          name="contact"
          method="post"
          onSubmit={handleSubmit}
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="contact" />
          <Styled.FormLabel>
            Email
            <Styled.Input
              name="email"
              value={formState.email || ''}
              type="email"
              onChange={handleChange}
            />
          </Styled.FormLabel>
          <Styled.NameInputs>
            <Styled.FormLabel>
              First Name
              <Styled.Input
                name="firstName"
                value={formState.firstName || ''}
                type="text"
                onChange={handleChange}
              />
            </Styled.FormLabel>
            <Styled.FormLabel>
              Last Name
              <Styled.Input
                name="lastName"
                value={formState.lastName || ''}
                type="text"
                onChange={handleChange}
              />
            </Styled.FormLabel>
          </Styled.NameInputs>
          <Styled.FormLabel>
            Message
            <Styled.TextArea
              name="message"
              value={formState.message || ''}
              rows={12}
              onChange={handleChange}
            />
          </Styled.FormLabel>
          {submitSuccessMsg && (
            <Styled.SuccessMessage>{submitSuccessMsg}</Styled.SuccessMessage>
          )}
          <Styled.SubmitButton type="submit" disabled={!!submitSuccessMsg}>
            Send Message
          </Styled.SubmitButton>
        </Styled.ContactForm>
      </Styled.ContentWrapper>
    </Styled.ContactFormWrapper>
  );
};

const ContactPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "blur rack.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );
  const heroImgData = data.hero.childImageSharp.fluid;
  return (
    <Layout page="contact">
      <SEO title="Contact Us" />
      <Styled.HeroSection>
        <Styled.BackgroundImage Tag="section" fluid={heroImgData}>
          <Styled.HeroContentWrapper>
            <H1>Let's Get in Touch</H1>
          </Styled.HeroContentWrapper>
        </Styled.BackgroundImage>
      </Styled.HeroSection>
      <Styled.ContactInfoSection>
        <Styled.MainContactInfo>
          <Styled.ContactInfoRow>
            <Styled.Label>Main Office</Styled.Label>
            <Styled.ContactValue>
              3019 Tanager Ave., Commerce, CA 90040
            </Styled.ContactValue>
          </Styled.ContactInfoRow>
          <Styled.ContactInfoRow>
            <Styled.Label>Our Phone</Styled.Label>
            <Styled.ContactValue>323-726-9424</Styled.ContactValue>
          </Styled.ContactInfoRow>
          <Styled.ContactInfoRow>
            <Styled.Label>Our Hours</Styled.Label>
            <Styled.ContactValue>Weekdays 7:00AM - 3:30PM</Styled.ContactValue>
          </Styled.ContactInfoRow>
          <Styled.ContactInfoRow>
            <Styled.Label>Our Email</Styled.Label>
            <Styled.ContactValue>
              <a href="mailto:sales@atbmh.com">sales@atbmh.com</a>
            </Styled.ContactValue>
          </Styled.ContactInfoRow>
        </Styled.MainContactInfo>
        <Styled.SocialMediaContactInfo>
          Find us online
          <Styled.SocialMediaLinks>
            <Styled.SocialMediaLink href={FACEBOOK_URL}>
              <FacebookLogoIcon size={28} />
            </Styled.SocialMediaLink>
            <Styled.SocialMediaLink href={INSTAGRAM_URL}>
              <InstagramLogoIcon size={28} />
            </Styled.SocialMediaLink>
            <Styled.SocialMediaLink href={LINKEDIN_URL}>
              <LinkedinLogoIcon size={28} />
            </Styled.SocialMediaLink>
            <Styled.SocialMediaLink href={YELP_URL}>
              <YelpLogoIcon size={28} />
            </Styled.SocialMediaLink>
          </Styled.SocialMediaLinks>
        </Styled.SocialMediaContactInfo>
      </Styled.ContactInfoSection>
      <Styled.FormSection>
        <ContactForm title="Let's Get in Touch" />
      </Styled.FormSection>
    </Layout>
  );
};

export default ContactPage;
